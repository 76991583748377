import { OrgType } from "@metriport/api-sdk";
import { states, territories } from "@metriport/shared";
import { sortBy } from "lodash";

export const genderAtBirthTypes = ["F", "M"] as const;
export type GenderAtBirth = (typeof genderAtBirthTypes)[number];

/**
 * @deprecated Move this to @metriport/shared
 */
class Constants {
  static readonly supportEmail = "support@metriport.com";
  static readonly reachoutToUs = `Please try again later or reachout to us on ${Constants.supportEmail}`;
  static readonly errorDefaultMessage = `Something went wrong. ${Constants.reachoutToUs}`;

  static readonly stripeConnectionFailure = `Could not initiate session with Stripe. ${Constants.reachoutToUs}`;
  static readonly isSandboxKey = "isSandbox";
  static contactEmail = "contact@metriport.com";
  static docsUrl = "https://docs.metriport.com/";
  static githubUrl = "https://github.com/metriport/metriport";
  static developersUrl = "/developers";
  static orgTypes: { [k in OrgType]: string } = {
    [OrgType.acuteCare]: "Acute Care",
    [OrgType.ambulatory]: "Ambulatory",
    [OrgType.hospital]: "Hospital",
    [OrgType.labSystems]: "Lab Systems",
    [OrgType.pharmacy]: "Pharmacy",
    [OrgType.postAcuteCare]: "Post Acute Care",
  };
  static gendersAtBirth: { [k in GenderAtBirth]: string } = {
    F: "Female",
    M: "Male",
  };
  static usStates = Object.entries(states).map(([key, value]) => ({ label: value, value: key }));
  static usTerritories = Object.entries(territories).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  static usStatesForAddress = sortBy(
    Constants.usStates.concat(Constants.usTerritories),
    state => state.label
  );
  static usStatesForDriverLicense = Constants.usStates;
}

export default Constants;
