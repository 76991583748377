import { Box, VStack, Heading, Text, Stack, SimpleGrid, Link } from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/card";
import { lowerCase } from "lodash";
import { useAnalyticsContext, Actions } from "@metriport/shared-internal";
import { FaBook, FaGithub, FaEnvelope, FaRocket } from "react-icons/fa";
import Constants from "../shared/constants";

// TODO get payment information and customer account information
// https://github.com/metriport/metriport/issues/21

export function FeatureCard({
  icon,
  label,
  description,
  url,
  target,
}: {
  icon: JSX.Element;
  label: string;
  description: string;
  url: string;
  target: string;
}) {
  const Analytics = useAnalyticsContext();
  return (
    <Link
      onClick={() => {
        Analytics.emit(Actions.navigate, "", { location: lowerCase(label) });
      }}
      href={url}
      target={target}
      _hover={{ textDecoration: "none" }}
    >
      <Card
        h={"100%"}
        border={"solid #d3d3d3 1px"}
        _hover={{ borderColor: "#748df0" }}
        boxShadow={"md"}
        borderRadius={20}
        maxW={"100%"}
      >
        <CardBody px={5} pb={5}>
          <Stack mt="6" spacing="4">
            {icon}
            <Heading size="md">{label}</Heading>
            <Text fontSize={18}>{description}</Text>
          </Stack>
        </CardBody>
      </Card>
    </Link>
  );
}

export default function Home() {
  return (
    <Box>
      <VStack p={10} align="start">
        <Heading>Welcome to Metriport!</Heading>
        <Text py={3} pb={10} fontSize={20}>
          Here are some resources to help you get started:
        </Text>
        <SimpleGrid spacing={3} columns={{ base: 1, lg: 2 }}>
          {cardItems.map((cardItem, i) => (
            <FeatureCard
              key={i}
              icon={cardItem.icon}
              label={cardItem.label}
              description={cardItem.description}
              url={cardItem.url}
              target={cardItem.target}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}

const cardItems = [
  {
    icon: <FaRocket size={25} color={"#748df0"} />,
    label: "Get Started",
    description: "Get your API key and start developing with Metriport!",
    url: Constants.developersUrl,
    target: "_self",
  },
  {
    icon: <FaBook size={25} color={"#748df0"} />,
    label: "View Our Docs",
    description: "Visit our docs for a quickstart guide and API reference.",
    url: Constants.docsUrl,
    target: "_blank",
  },
  {
    icon: <FaGithub size={25} color={"#748df0"} />,
    label: "Open Source",
    description: "Checkout our public GitHub repo and give us a star!",
    url: Constants.githubUrl,
    target: "_blank",
  },
  {
    icon: <FaEnvelope size={25} color={"#748df0"} />,
    label: "Contact Us",
    description: `Let us know what you're using Metriport for, and what we can help with. `,
    url: `mailto:${Constants.contactEmail}`,
    target: "_blank",
  },
];
