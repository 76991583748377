import { User } from "@propelauth/react";
import { Customer } from "../../../api/customer";
import { UserKeys } from "../../../domain/api-key";

type BaseAppState = {
  keyIds?: UserKeys;
  authToken?: string;
  customer?: Customer;
  user?: User;
  userRole?: string;
  hasMapiAccess?: boolean;
  isImpersonating?: boolean;
};

export type AppState =
  | (BaseAppState & { isLoaded: false })
  | (Required<Omit<BaseAppState, "customer">> &
      Pick<BaseAppState, "customer"> & { isLoaded: true });

export enum AppStateActionType {
  update,
}

export interface AppStateAction {
  type: AppStateActionType;
  newState: BaseAppState | AppState;
}

export const reducer = (state: AppState, action: AppStateAction) => {
  switch (action.type) {
    case AppStateActionType.update:
      return {
        ...state,
        ...action.newState,
      };

    default:
      return state;
  }
};

export const initialState: AppState = {
  isLoaded: false,
};
