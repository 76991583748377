import { ChakraProps, Flex, Input, useMediaQuery, useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useAnalyticsContext, Actions, Features } from "@metriport/shared-internal";
import { retryWebhookRequests } from "../../api/webhook-status";
import { capture } from "../../shared/capture";
import { useAppContext } from "../contexts/app";
import { Button } from "../medical/shared-components/button";
import Constants from "../shared/constants";
import { Label } from "../shared/Label";
import { sleep } from "../shared/util";

export default function WebhookRequests(
  props: {
    webhookRequestsProcessing: number;
    webhookRequestsFailed: number;
    onRetryStart: () => void;
    onRetryEnd: () => void;
    onError: (msg: string) => void;
    isSubmitting: boolean;
  } & ChakraProps
) {
  const {
    webhookRequestsProcessing,
    webhookRequestsFailed,
    onRetryStart,
    onRetryEnd,
    onError,
    isSubmitting,
  } = props;
  const { state } = useAppContext();
  const toast = useToast();
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const Analytics = useAnalyticsContext();

  const retry = useCallback(async () => {
    Analytics.emit(Actions.retry, Features.webhookUrl);
    onRetryStart();
    try {
      await retryWebhookRequests();
      toast({
        title: "Retrying...",
        status: "success",
        duration: 2_000,
        isClosable: true,
      });
      await sleep(2_000);
    } catch (err) {
      capture.error(err, { extra: { context: `webhook.retry` } });
      onError(Constants.errorDefaultMessage);
    }
    onRetryEnd();
  }, [onRetryStart, onRetryEnd, onError, state.authToken, toast]);

  return (
    <Flex alignItems="left" direction={isLargerThan1000 ? "row" : "column"}>
      <Flex alignItems="left">
        <Label>Requests processing:</Label>
        <Input
          variant="filled"
          value={webhookRequestsProcessing >= 0 ? webhookRequestsProcessing : "--"}
          readOnly
          mt={3}
          fontFamily="courier"
          flexGrow={2}
        />
      </Flex>
      <Flex alignItems="left" ml={isLargerThan1000 ? 10 : undefined}>
        <Label>Requests failed:</Label>
        <Input
          variant="filled"
          value={webhookRequestsFailed >= 0 ? webhookRequestsFailed : "--"}
          readOnly
          mt={3}
          fontFamily="courier"
          flexGrow={2}
        />
      </Flex>
      <Button
        isLoading={isSubmitting}
        minWidth={130}
        ml={3}
        mt={3}
        onClick={retry}
        disabled={webhookRequestsFailed < 1}
      >
        Retry
      </Button>
    </Flex>
  );
}
