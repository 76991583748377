import { Box, Flex, Heading, Skeleton, Stack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAnalyticsContext, Actions, Features } from "@metriport/shared-internal";
import { getStripeRedirectUrl } from "../../api/api";
import { getProducts, Products } from "../../api/products";
import { getUsage, Usage } from "../../api/usage";
import { capture } from "../../shared/capture";
import { useAppContext } from "../contexts/app";
import { Button } from "../medical/shared-components/button";
import Constants from "../shared/constants";
import useMetriportToast from "../medical/shared-logic/useMetriportToast";
import MedicalAPIProduct from "./medical-api-product";
import StripeRedirectAlert from "./stripe-redirect-alert";
import UpcomingInvoice from "./upcoming-invoice";

export default function Billing() {
  const { state } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [usage, setUsage] = useState<Usage | null>(null);
  const [products, setProducts] = useState<Products | null>(null);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [noCustomer, setNoCustomer] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const toast = useMetriportToast();
  const Analytics = useAnalyticsContext();

  const { isOpen, onOpen, onClose } = useDisclosure({});

  useEffect(() => {
    async function fetchData() {
      if (state.isLoaded && state.customer) {
        const cxId = state.customer.id;
        try {
          const [products, usage] = await Promise.all([getProducts(cxId), getUsage(cxId)]);
          setProducts(products);

          if (usage) setUsage(usage);
        } catch (error) {
          const msg = "Error retrieving Products/Usage";
          toast.error({ title: msg });
          capture.message(msg, {
            extra: { context: `billing.get`, error, cxId },
            level: "error",
          });
          setNoCustomer(true);
        }
        setIsLoading(false);
      } else {
        setNoCustomer(true);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [setIsLoading, state.isLoaded, state.customer]);

  const manageSubscription = async (props?: { subscribeDevices?: boolean }) => {
    const subType = props?.subscribeDevices ? "devices" : "medical";
    Analytics.emit(Actions.manage, Features.subscription, { type: subType });

    setIsSubscribing(true);
    onOpen();

    setTimer(
      // This need to be in settimeout if the user cancels the subscription shouldnt be created
      setTimeout(async () => {
        const redirectUrl = await getStripeRedirectUrl({
          subscribeDevices: props?.subscribeDevices,
        });

        if (!redirectUrl) {
          setErrorMessage(Constants.stripeConnectionFailure);
          onCancel();
          return;
        }

        window.location.href = redirectUrl;
      }, 3000)
    );
  };

  const onCancel = () => {
    timer && clearTimeout(timer);
    onClose();
    Analytics.emit(Actions.cancel, Features.subscription);
    setIsSubscribing(false);
  };

  const clearErrMessage = () => {
    setErrorMessage(null);
  };

  const viewUpcomingInvoices = () => {
    manageSubscription();
    Analytics.emit(Actions.view, Features.invoices);
  };

  if (state.isLoaded) {
    const { medicalStripeSubscriptionItemId, devicesStripeSubscriptionItemId } =
      state.customer ?? {};
    const medicalProduct = products?.products.find(product => product.productCode === "medical");

    return (
      <VStack p={10} align="start">
        <Flex
          w={{ base: "full", xl: "49%" }}
          flexDirection={{ base: "column", xl: "row" }}
          justifyContent={"space-between"}
          alignItems={{ base: "flex-start", xl: "center" }}
        >
          <Box mb={3}>
            <Heading mb={2}>Products and Billing</Heading>
            <Text fontSize={18}>Manage your product and billing details.</Text>
          </Box>
        </Flex>

        {noCustomer && <>Could not load customer information. Please try again later.</>}

        <Skeleton w={"100%"} isLoaded={!isLoading}>
          <Stack direction={{ base: "column", xl: "row" }} spacing="18px" w={"100%"}>
            {medicalProduct && (
              <MedicalAPIProduct
                product={medicalProduct}
                isActive={!!medicalStripeSubscriptionItemId}
                isSubscribing={isSubscribing}
                onSubscribe={manageSubscription}
              />
            )}
          </Stack>
        </Skeleton>

        {(!!devicesStripeSubscriptionItemId || !!medicalStripeSubscriptionItemId) && (
          <Skeleton w={{ base: "full" }} isLoaded={!isLoading}>
            {usage && (
              <>
                <Flex
                  mb={5}
                  flexDirection={{ base: "column", sm: "row" }}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Heading>Upcoming Invoice</Heading>
                  <Button isLoading={isSubscribing} onClick={viewUpcomingInvoices}>
                    Previous Invoices
                  </Button>
                </Flex>
                <UpcomingInvoice usage={usage} />
              </>
            )}
          </Skeleton>
        )}

        <StripeRedirectAlert
          isOpen={isOpen}
          onCancel={onCancel}
          errorMessage={errorMessage}
          onCloseError={clearErrMessage}
        />
      </VStack>
    );
  }

  return null;
}
