import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Facility, MetriportMedicalApi, PatientDTO, ResponseMeta } from "@metriport/api-sdk";
import { Actions, Features, useAnalyticsContext } from "@metriport/shared-internal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isApiKeyGenerated } from "../../../domain/api-key";
import { isMapiAccessActive, isMedicalSubscriptionActive } from "../../../domain/customer";
import { capture } from "../../../shared/capture";
import { useAppContext } from "../../contexts/app";
import { enterKeys } from "../../shared/form/Input";
import { useIsUserInSandbox } from "../../shared/useIsUserInSandbox";
import NoApiKey from "../no-api-key";
import NoMapiAccess from "../no-mapi-access";
import { Button, Button as MetriportButton } from "../shared-components/button";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import useMetriportToast from "../shared-logic/useMetriportToast";
import PatientForm from "./form";
import PatientGrid, { PatientGridRef } from "./grid";

export default function Patients({ api }: { api: MetriportMedicalApi }) {
  const { state } = useAppContext();
  const { isUserInSandbox } = useIsUserInSandbox(state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useMetriportToast();
  const navigate = useNavigate();
  const [patientToEdit, setPatientToEdit] = useState<PatientDTO | undefined>(undefined);
  const [facilities, setFacilities] = useState<Facility[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [isLoadingPatients, setIsLoadingPatients] = useState(false);
  const [filters, setFilters] = useState("");
  const [totalAmountOfPatients, setTotalAmountOfPatients] = useState<number | undefined>(undefined);
  const [tableFromTop, setTableFromTopHeight] = useState(0);
  const Analytics = useAnalyticsContext();
  const gridRef = useRef<PatientGridRef | null>(null);

  const getPatients = useCallback(
    async (
      pageUrl?: string | undefined,
      localFilters?: string | undefined
    ): Promise<{ patients: PatientDTO[]; meta: ResponseMeta } | undefined> => {
      if (!isMapiAccessActive(state, isUserInSandbox)) return;
      try {
        setIsLoadingPatients(true);
        localFilters && setFilters(localFilters);
        const { patients, meta } = pageUrl
          ? await api.listPatientsPage(pageUrl)
          : // TODO 483 remove the pagination object once we're through with the pagination rollout (paginates by default)
            await api.listPatients({ pagination: { count: 50 }, filters: localFilters });
        if (meta.itemsInTotal != undefined) setTotalAmountOfPatients(meta.itemsInTotal);
        return { patients, meta };
      } catch (error) {
        const msg = "Error retrieving patients";
        toast.error({ title: msg });
        capture.error(msg, {
          extra: { context: `patients.get`, error },
        });
        return undefined;
      } finally {
        setIsLoadingPatients(false);
      }
    },
    [api]
  );

  const getFacilities = useCallback(async () => {
    if (!isMapiAccessActive(state, isUserInSandbox)) return;
    try {
      setIsLoadingFacilities(true);
      const facilities = await api.listFacilities();
      setFacilities(facilities);
    } catch (error) {
      const msg = "Error retrieving facilities";
      toast.error({ title: msg });
      capture.error(msg, {
        extra: { context: `facilities.get`, error },
      });
    } finally {
      setIsLoadingFacilities(false);
    }
  }, [api]);

  useEffect(() => {
    setIsLoading(isLoadingFacilities || isLoadingPatients);
  }, [isLoadingFacilities, isLoadingPatients]);

  useEffect(() => {
    getFacilities();
    const element = document.getElementById("table-container");
    if (element) {
      setTableFromTopHeight(element.getBoundingClientRect().top);
    }
  }, [state]);

  function onPatientFormClosed(patient: PatientDTO, isDelete?: boolean) {
    if (isDelete) {
      search();
    } else if (patientToEdit) {
      update();
    } else {
      search();
    }
    Analytics.emit(Actions.submit, Features.form, { type: Features.patient });
    setPatientToEdit(undefined);
  }

  function onPatientCreate() {
    Analytics.emit(Actions.create, Features.patient);
    onOpen();
  }

  function onPatientEdit(patient: PatientDTO): void {
    Analytics.emit(Actions.update, Features.patient);
    setPatientToEdit(patient);
    onOpen();
  }

  function onPatientClose() {
    onClose();
    setPatientToEdit(undefined);
  }

  const apiKeyGenerated = useMemo(() => isApiKeyGenerated(state.keyIds), [state.keyIds]);

  if (state.isLoaded && !apiKeyGenerated) {
    return <NoApiKey />;
  }

  if (
    state.isLoaded &&
    apiKeyGenerated &&
    (!isMedicalSubscriptionActive(state, isUserInSandbox) ||
      !isMapiAccessActive(state, isUserInSandbox))
  ) {
    return <NoMapiAccess />;
  }

  function handlePatientClick(patientId: string) {
    const path = isUserInSandbox ? `/sandbox/patient/${patientId}` : `/patient/${patientId}`;
    navigate(path);
  }

  function search() {
    gridRef?.current?.resetSearch(filters);
  }
  function update() {
    gridRef?.current?.updatePage(filters);
  }
  function clearFilters() {
    setFilters("");
    gridRef?.current?.resetSearch("");
  }

  return (
    <>
      <Box px={5}>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Heading size="xl">Patients</Heading>
          <Box>
            <MetriportButton mr={4} onClick={onPatientCreate}>
              Create Patient
            </MetriportButton>
          </Box>
        </Flex>
        <Divider my={6} />
        <Flex justifyContent={"space-between"} mb={5}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
              <SearchIcon />
            </InputLeftElement>
            {/* TODO Refactor this/shared input so we can reuse that component */}
            <Input
              minW={"300px"}
              w={"35%"}
              value={filters}
              placeholder="Search for patients"
              onClick={() => Analytics.emit(Actions.search, Features.patient)}
              onChange={e => setFilters(e.target.value)}
              onKeyUp={key => {
                if (enterKeys.includes(key.code)) search();
              }}
              _hover={{ borderColor: METRIPORT_PRIMARY }}
              _active={{ borderColor: METRIPORT_PRIMARY }}
              _focus={{ borderColor: METRIPORT_PRIMARY }}
            />
            <Button onClick={search} ml={2}>
              Search
            </Button>
            <Button onClick={clearFilters} ml={2}>
              Clear
            </Button>
          </InputGroup>
          {/* V 1.5  ADD FILTERS */}
          {/* <Button
            style={{ backgroundColor: "transparent", borderColor: "#748df0", color: "#748df0" }}
            leftIcon={<BsFilter />}
            variant="outline"
          >
            Filter by facility
          </Button> */}
        </Flex>
        <Box
          id="table-container"
          style={{ width: "100%", height: `calc(95vh - ${tableFromTop}px)` }}
        >
          <PatientGrid
            ref={gridRef}
            isLoading={isLoading}
            getPatients={getPatients}
            facilities={facilities}
            onPatientClick={handlePatientClick}
            onPatientEdit={onPatientEdit}
          />
        </Box>
        <Text align={"right"} pt={3}>
          Total amount of patients: {totalAmountOfPatients}
        </Text>
      </Box>

      <PatientForm
        selectedPatient={patientToEdit}
        onPatientAction={onPatientFormClosed}
        facilities={facilities}
        isOpen={isOpen}
        onClose={onPatientClose}
      />
    </>
  );
}
