import { Alert, AlertIcon, Box } from "@chakra-ui/react";
import { isApiKeyGenerated } from "../../domain/api-key";
import { isMedicalSubscriptionActive } from "../../domain/customer";
import { useAppContext } from "../contexts/app";
import { AppState } from "../contexts/app/reducer";
import { MedicalAppStateProvider } from "../contexts/medical";
import { useIsUserInSandbox } from "../shared/useIsUserInSandbox";
import NoApiKey from "./no-api-key";
import NoMapiAccess from "./no-mapi-access";
import Patients from "./patients";
import { useMetriportApi } from "./shared-logic/useMetriportApi";

export default function Medical() {
  const { state } = useAppContext();
  const { isUserInSandbox } = useIsUserInSandbox(state);
  const apiKeyGenerated = isApiKeyGenerated(state.keyIds);

  if (state.isLoaded && !apiKeyGenerated) {
    return <NoApiKey />;
  }

  if (state.isLoaded && apiKeyGenerated && !isMedicalActive(state, isUserInSandbox)) {
    return <NoMapiAccess />;
  }

  if (state.isLoaded) {
    const metriportAPI = useMetriportApi();

    return (
      <>
        {isUserInSandbox && (
          <Alert status="info" mb={50}>
            <AlertIcon />
            The sandbox mode allows you to try out the Medical API with some example de-identified
            clinical data. To learn more, please visit our&nbsp;
            <a href="https://docs.metriport.com/medical-api/more-info/sandbox" target="_blank">
              <u>
                <b>documentation</b>
              </u>
            </a>
            .
          </Alert>
        )}
        <Box p={10}>
          <MedicalAppStateProvider>
            <Patients api={metriportAPI}></Patients>
          </MedicalAppStateProvider>
        </Box>
      </>
    );
  }

  return null;
}

export const isMedicalActive = (state: AppState, isSandbox: boolean): boolean => {
  return isMedicalSubscriptionActive(state, isSandbox);
};
