import {
  PatientOverview,
  PatientQueryParamProps,
  PatientQueryParams,
  SharedStateProvider,
  getValidPatientTab,
} from "@metriport/shared-internal";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getOSSBaseURL } from "../../../api/apiOSS";
import { isApiKeyGenerated } from "../../../domain/api-key";
import { useAppContext } from "../../contexts/app";
import { useIsUserInSandbox } from "../../shared/useIsUserInSandbox";
import NoApiKey from "../no-api-key";

export default function PatientPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { patientId } = useParams();
  const { state } = useAppContext();
  const { isUserInSandbox } = useIsUserInSandbox(state);

  if (!patientId || !state.isLoaded) {
    return null;
  }

  const patientTab = getValidPatientTab(searchParams.get("patientTab"));
  const sectionName = searchParams.get("sectionName") ?? undefined;
  const resourceId = searchParams.get("resourceId") ?? undefined;

  function setPatientQueryParams(update: Partial<PatientQueryParams>): void {
    const { patientTab, sectionName, resourceId } = update;
    setSearchParams(prevState => {
      if (patientTab) prevState.set("patientTab", patientTab);
      if (sectionName) prevState.set("sectionName", sectionName);
      if (resourceId) prevState.set("resourceId", resourceId);
      return prevState;
    });
  }

  function clearPatientQueryParams(): void {
    setSearchParams({});
  }

  const patientQueryParams: PatientQueryParamProps = {
    patientTab,
    sectionName,
    resourceId,
    setPatientQueryParams,
    clearPatientQueryParams,
  };

  const apiKeyGenerated = isApiKeyGenerated(state.keyIds);

  if (!apiKeyGenerated) {
    return <NoApiKey />;
  }

  const baseUrl = useMemo(() => {
    return getOSSBaseURL(isUserInSandbox);
  }, [isUserInSandbox]);

  return (
    <SharedStateProvider authToken={state.authToken} baseUrl={baseUrl} ehrDetails={undefined}>
      <PatientOverview
        isImpersonating={state.isImpersonating}
        patientId={patientId}
        patientQueryParams={patientQueryParams}
      />
    </SharedStateProvider>
  );
}
