import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { AppState } from "../contexts/app/reducer";
import Constants from "./constants";

export const useIsUserInSandbox = (
  state: AppState
): {
  sandboxMap: { [key: string]: boolean };
  isUserInSandbox: boolean;
  setIsUserInSandbox: (newVal: boolean) => void;
} => {
  const username = state.user?.userId || "";
  const location = useLocation();

  const isInitialSandbox = location.pathname.includes("sandbox");

  const [sandboxMap, _setIsSandbox] = useLocalStorage<{ [key: string]: boolean }>(
    Constants.isSandboxKey,
    {
      [username]: isInitialSandbox,
    }
  );

  function setIsUserInSandbox(newVal: boolean) {
    _setIsSandbox({
      ...sandboxMap,
      [username]: newVal,
    });
  }

  useEffect(() => {
    if (sandboxMap[username] !== true && isInitialSandbox) {
      setIsUserInSandbox(true);
    }
  }, []);

  const isUserInSandbox = sandboxMap[username] ?? isInitialSandbox;

  return {
    sandboxMap,
    isUserInSandbox,
    setIsUserInSandbox,
  };
};
