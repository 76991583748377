import { Box, Heading, Switch, Text, VStack } from "@chakra-ui/react";
import { Actions, Features, useAnalyticsContext } from "@metriport/shared-internal";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { updateBaseURL as updateBaseURLOSS } from "../../api/apiOSS";
import { capture } from "../../shared/capture";
import { isDevEnv, isStagingEnv } from "../../shared/util";
import { useAppContext } from "../contexts/app";
import useMetriportToast from "../medical/shared-logic/useMetriportToast";
import { useIsUserInSandbox } from "../shared/useIsUserInSandbox";

const sandboxPaths = new Map([
  ["patient", "/patients"],
  ["patients", "/patients"],
  ["facility", "/facility"],
  ["developers", "/developers"],
]);

export function handleNavigation(path: string, isSandbox: boolean, navigate: NavigateFunction) {
  const hasSandboxPrefix = path.startsWith("/sandbox");
  if ((isSandbox && hasSandboxPrefix) || (!isSandbox && !hasSandboxPrefix)) return;

  const basePath = hasSandboxPrefix ? path.replace("/sandbox", "") : path;
  const segments = basePath.split("/").filter(segment => segment.length > 0);
  const pathType = segments[0];
  const newPathType = pathType ? sandboxPaths.get(pathType) : pathType;

  if (!newPathType) return;

  const sandboxPrefix = isSandbox ? "/sandbox" : "";
  const finalPath = `${sandboxPrefix}${newPathType}`;

  navigate(finalPath, { replace: false });
  return;
}

export default function SandboxToggle({
  minimized,
  onToggle,
}: {
  minimized?: boolean;
  onToggle?: () => void;
}) {
  const toast = useMetriportToast();
  const { state } = useAppContext();
  const Analytics = useAnalyticsContext();
  const { isUserInSandbox, setIsUserInSandbox } = useIsUserInSandbox(state);
  const isMinimized = !!minimized;

  const location = useLocation();
  const navigate = useNavigate();

  function onSandboxToggle() {
    const newIsSandbox = !isUserInSandbox;
    setIsUserInSandbox(newIsSandbox);
    updateBaseURLOSS(newIsSandbox);
    Analytics.emit(Actions.toggle, Features.sandbox, {
      isOn: newIsSandbox,
    });
    capture.updateSandboxEnvironment(newIsSandbox);

    handleNavigation(location.pathname, newIsSandbox, navigate);

    if ((isStagingEnv() || isDevEnv()) && newIsSandbox) {
      toast.warning({
        title: "Heads up! You've enabled the sandbox mode in staging. Key generation is disabled.",
        duration: 5000,
      });
    }

    onToggle && onToggle();
  }

  return (
    <Box>
      <VStack align="left">
        {!isMinimized && (
          <>
            <Heading>Sandbox</Heading>
            <Text>
              Toggle the sandbox mode on/off to access your Sandbox-specific API key & settings.
              This is a website-wide action, all features in the dashboard are impacted by this
              change.
            </Text>
          </>
        )}
        <Switch
          id="sandbox-mode"
          size="lg"
          onChange={onSandboxToggle}
          isChecked={isUserInSandbox}
        />
      </VStack>
    </Box>
  );
}
