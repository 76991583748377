export const envTypeEnvVarName = "REACT_APP_ENV_TYPE";
export const envTypeDev = "development";
export const envTypeSandbox = "sandbox";
export const envTypeStaging = "staging";
export const envTypeProd = "production";

export function getEnvType(): string {
  return getEnvVarOrFail(envTypeEnvVarName);
}
export function isDevEnv(): boolean {
  return getEnvType() === envTypeDev;
}

export function getEnvVar(varName: string): string | undefined {
  return process.env[varName];
}

export function isProdEnv(): boolean {
  return getEnvType() === envTypeProd;
}

export function isStagingEnv(): boolean {
  return getEnvType() === envTypeStaging;
}

export function getEnvVarOrFail(varName: string): string {
  const value = getEnvVar(varName);
  if (!value || value.trim().length < 1) {
    throw new Error(`Missing ${varName} env var`);
  }
  return value;
}

export function bytesToSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function isNoAccessError(status: number) {
  return status === 403;
}

const websiteValidationRegex =
  /^(?:http:\/\/|https:\/\/)?[\w\d]+(?:[-.]{1}[\w\d]+)*\.[\w]{2,20}(?::[\d]{1,20})?(?:\/\S*)?$/;

export function validateWebsite(website: string): boolean {
  const isWebsiteValid = websiteValidationRegex.test(website);

  return isWebsiteValid;
}
