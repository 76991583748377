import { useMemo } from "react";
import { MetriportMedicalApi } from "@metriport/api-sdk";
import axios from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
// TODO: 2064 Will add to shared when its used by monorepo
import { useAppContext } from "../../contexts/app";
import { getOSSBaseURL } from "../../../api/apiOSS";
import { useIsUserInSandbox } from "../../shared/useIsUserInSandbox";

dayjs.extend(duration);

const mapiTimeout = dayjs.duration({ seconds: 29 });
const headers: Record<string, string> = {
  [MetriportMedicalApi.headers.clientApp]: "Metriport-Dashboard",
};

export function useMetriportApi(): MetriportMedicalApi {
  const { state } = useAppContext();
  const { isUserInSandbox } = useIsUserInSandbox(state);

  const metriportAPI = useMemo(() => {
    const options = {
      axios,
      timeout: mapiTimeout.asMilliseconds(),
      mode: "jwt" as const,
      baseAddress: getOSSBaseURL(isUserInSandbox),
      ...headers,
    };
    return new MetriportMedicalApi(state.authToken ?? "", options);
  }, [state.authToken]);

  return metriportAPI;
}
